// FILM APP SCRIPT
// ==================================================
$(document).ready(function() {
	$('#headerSection .scroll-scrim, #musicNavLink').click(function() {
		let scroll_top = window.scrollY + $('#film')[0].getBoundingClientRect().top;
		window.scrollTo({
			top: scroll_top,
			behavior: 'smooth'
		});
	});
	$('#eventsNavLink').click(function() {
		let scroll_top = window.scrollY + $('#events')[0].getBoundingClientRect().top;
		window.scrollTo({
			top: scroll_top,
			behavior: 'smooth'
		});
	});
});