// LOADED SCRIPT
// ==================================================
function setHtmlLoaded() {
	window.scroll(0,0);
	$('html').addClass('loaded');
}

$(document).ready(function() {
	// set route
	route_change(window.location.pathname+window.location.hash);

	// set loaded handler
	let home_image = $('#homeContent .home-image img');
	home_image.on('load', () => home_image.addClass('image-loaded'));
	if(home_image[0].complete)
		home_image.addClass('image-loaded');

	// perform delayed actions
	setTimeout(setHtmlLoaded, 500);
});