// timeouts
let fadein_timeout = null;

// durations
const fadeout_duration = 500 + 250;

function route_change(route_path) {
	let route_match = /\/?(#[\w]*)/.exec(route_path);
	let route = route_match ? route_match[1].toLowerCase() : '';
	let route_word = route.length > 0 && route[0] === '#' ? route.substr(1) : route;
	let content_pages = $('#mainContent .content-page');
	let content_id = '#' + route_word + 'Content';
	let option_id = '#' + route_word + 'Nav';

	let found_content = $(content_id);
	if(route_word.length === 0 || found_content.length === 0) {
		found_content = $('#homeContent');
		option_id = '#homeNav';
	}

	if(!found_content.hasClass('show-content')) {
		clearTimeout(fadein_timeout);
		$('#mainContent .content-page').removeClass('show-content');
		fadein_timeout = setTimeout(() => found_content.addClass('show-content'), fadeout_duration);
	}

	let found_option = $(option_id);
	if(!found_option.hasClass('selected-option')) {
		$('#navBar .nav-option').removeClass('selected-option');
		found_option.addClass('selected-option');
	}
}

// history.pushState alternative that calls the proper router handlers
function router_pushState(state, title, route_path) {
	window.history.pushState(state, title, route_path);
	route_change(route_path)
}

// Pop state handler
$(window).on('popstate', function() {
	route_change(window.location.hash);
})

$('#homeNav').click(() => router_pushState('','',window.location.pathname));
$('#synopsisNav').click(() => router_pushState('','',window.location.pathname+'#synopsis'));
$('#videosNav').click(() => router_pushState('','',window.location.pathname+'#videos'));
$('#pressNav').click(() => router_pushState('','',window.location.pathname+'#press'));